/* 글로벌 스타일 */

/* 아이콘 간격 개선 */
.svg-inline--fa {
    margin-right: 10px !important;
}

/* 버튼 내부 아이콘 간격 조정 */
.btn .svg-inline--fa {
    margin-right: 8px !important;
}

/* 간격이 필요 없는 특정 아이콘 조정 */
.close .svg-inline--fa,
.navbar-toggler .svg-inline--fa {
    margin-right: 0 !important;
}

/* 상단 네비바 언어/단위 선택 스타일 */
.navbar .nav-item {
    transition: all 0.2s ease;
}

.select-label {
    font-size: 0.9rem;
    white-space: nowrap;
    opacity: 0.8;
}

.top-menu-select-group .selector-item {
    min-height: 46px;
}

@media (max-width: 991px) {
    .top-menu-select-group {
        margin-top: 15px;
        width: 100%;
    }
    
    .top-menu-select-group .selector-item {
        border-left: none !important;
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        padding-left: 0 !important;
    }
}

/* 선택 컴포넌트 스타일 개선 */
.select__control {
    box-shadow: none !important;
}

.select__control:hover {
    border-color: #375a7f !important;
}

/* 거리 값 스타일 */
.distance-value {
    font-weight: 500;
    letter-spacing: 0.4px;
}