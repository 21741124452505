.footer {
    background-color: var(--bg-tertiary);
    padding: 50px 0;
    margin-top: 100px;
    color: var(--text-secondary);
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.footer-heading {
    color: var(--text-primary);
    font-weight: 600;
    margin-bottom: 20px;
    position: relative;
    padding-bottom: 10px;
}

.footer-heading::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 40px;
    height: 2px;
    background-color: var(--primary-color);
}

.footer-description {
    font-size: 0.9rem;
    margin-bottom: 20px;
    line-height: 1.6;
}

.copyright {
    font-size: 0.85rem;
    opacity: 0.7;
}

.footer-links {
    margin-bottom: 0;
}

.footer-links li {
    margin-bottom: 10px;
}

.footer-link {
    color: var(--text-secondary);
    text-decoration: none;
    transition: color var(--transition-speed);
    display: inline-block;
    position: relative;
}

.footer-link::after {
    content: '';
    position: absolute;
    width: 0;
    height: 1px;
    bottom: -2px;
    left: 0;
    background-color: var(--primary-color);
    transition: width var(--transition-speed);
}

.footer-link:hover {
    color: var(--primary-color);
}

.footer-link:hover::after {
    width: 100%;
}

.notice-text {
    font-size: 0.8rem;
    opacity: 0.7;
    display: block;
    margin-top: 10px;
}

@media (max-width: 768px) {
    .footer {
        padding: 30px 0;
    }
    
    .footer-heading {
        margin-bottom: 15px;
    }
}