:root {
  --primary-color: #375a7f;
  --secondary-color: #444;
  --success-color: #00bc8c;
  --info-color: #3498db;
  --warning-color: #f39c12;
  --danger-color: #e74c3c;
  --light-color: #adb5bd;
  --dark-color: #303030;
  
  --bg-primary: #222;
  --bg-secondary: #272727;
  --bg-tertiary: #303030;
  
  --text-primary: #fff;
  --text-secondary: rgba(255, 255, 255, 0.75);
  --text-muted: rgba(255, 255, 255, 0.5);
  
  --border-radius: 0.25rem;
  --box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  --transition-speed: 0.3s;
}

body {
  background-color: var(--bg-primary);
  color: var(--text-primary);
  margin: 0;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.card {
  background-color: var(--bg-secondary);
  border: none;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
}

.btn {
  border-radius: var(--border-radius);
  font-weight: 500;
  transition: all var(--transition-speed);
}

.btn-primary {
  background-color: var(--primary-color);
}

.btn-primary:hover {
  background-color: rgba(55, 90, 127, 0.8);
}

.form-control {
  background-color: var(--bg-tertiary);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: var(--text-primary);
  border-radius: var(--border-radius);
}

.navbar {
  background-color: var(--bg-tertiary) !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.table {
  color: var(--text-primary);
}

.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: var(--bg-tertiary);
}

::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(55, 90, 127, 0.8);
}

/* Transitions and animations */
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .container-fluid {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}